<template>
  <v-card class="basket-footer primary pa-3" height="74">
    <v-row>
      <v-col cols="6" class="d-flex flex-column align-start justify-center">
        <div>
        <span class="smallFont onPrimary--text"> جمع سبدخرید: </span>
        </div>
        <div>
        <span v-text="price" class="onPrimary--text"></span>
        </div>
      </v-col>
      <v-col cols="6" class="d-flex align-center justify-end">
        <v-btn @click="countiue()" width="120" height="37" class="secondaryText--text py-3 footer-btn font-weight-black" v-text="title"></v-btn>
      </v-col>
    </v-row>
  </v-card>
</template>
<style lang="scss" scoped>
.basket-footer{
    width: 100%;
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 1;
}
</style>

<script>
import { changeTypePrice_format } from '@/models/changeType'
import { mapState } from 'vuex'
import router from '@/router'
import { basketResiver, submitReciver, setTimeSend } from '../../models/Basket'
import Store from '@/store/index'
export default {
  props: ['title'],
  computed: {
    ...mapState({
      price (state) {
        const price = changeTypePrice_format({
        price: state.basket.priceBasket,
        Tax_price: '',
      })
        return price
      },
      addressBasket: state => state.basket.addressBasket,
      basket: state => state.basket.basket,
      time_basket: state => state.basket.set_time_basket,
      profile: state => state.user.user
    })
  },
  methods: {
  countiue () {
    const route = this.$router.history.current.name
    switch (route) {
      case 'basket':
        if (this.profile.first_name == '' || this.profile.last_name == '') {
          this.$emit('open_dialog', true)
        } else {
          router.push('/reciever')
        }
        break;
      case 'reciever':
        if (this.addressBasket.length >= 1) {
          submitReciver(this.addressBasket)
        } else {
          Store.dispatch('alertError', {show: true, type: 'nullAddressBasket'})
          setTimeout(() => {
            Store.dispatch('alertError', {show: false, type: 'nullAddressBasket'})
          }, 2500)
        }
        break;
       case 'send_time':
         if (this.time_basket.length != 0) {
           setTimeSend(this.time_basket)
         } else {
          Store.dispatch('alertError', {show: true, type: 'null_Time_basket'})
          setTimeout(() => {
            Store.dispatch('alertError', {show: false, type: 'null_Time_basket'})
          }, 2500)
        }
        break;
    }
  }
  }
}
</script>