<template>
    <div class="text-center">
    <v-snackbar
      color="error"
      class="pt-12 alert-radius"
      text
      fixed
      top
      centered
      v-model="open"
      :timeout="timeout"
    >
      {{ textMessage }}
    </v-snackbar>
  </div>
</template>

<script >
import { mapState } from 'vuex'
import {alertMessage} from '@/assets/messageAlert'
export default ({
    data: () => ({
      timeout: 9000
    }),
    computed: {
      ...mapState({
        type: state => state.alert.error.type,
        open: state => state.alert.error.show
      }),
      textMessage () {
        const textMessage = alertMessage.error[this.type]
        return textMessage
      }
    }
})
</script>