<template>
<div>
    <v-card v-for="slide in slides.deliveries" :key="slide.solar_date" class="pa-1 mt-2 card">
            <v-row class="text-center d-flex flex-column pa-2 mt-1">
            <span class="primary--text medium-font" v-text="slide.Day_Title"></span>
            <small class="primary--text medium-font" v-text="slide.solar_date"></small>
            </v-row>
            <v-divider class="my-2"></v-divider>
            <div class="overfow-y">
        <v-card class="card flat-card" color="surface">
          <v-card-text class="pa-0">
              <v-radio-group v-model="radioGroup">
               <v-row v-for="shift in slide.shifts" :key="shift.Delivery_Shift_ID_PK" class="d-flex align-center mb-1">
                <v-col cols="8">
                <v-radio v-if="shift.had_capacity == 1" :label="shift.Shift_Title" :value="slide.solar_date +','+ shift.Delivery_Shift_ID_PK"></v-radio>
                <v-radio v-else disabled :label="shift.Shift_Title" :value="slide.solar_date +','+ shift.Delivery_Shift_ID_PK"></v-radio>
                </v-col>
                <v-col class="text-start">
                  <span v-if="shift.had_capacity == 0" class="primaryText--text medium-font">تکمیل</span>
                </v-col>
               </v-row>             
              </v-radio-group>
          </v-card-text>
        </v-card>
       </div>
          </v-card>
          </div>
</template>

<script>
import { mapState } from 'vuex'
import store from '@/store'
export default {
  computed: {
    ...mapState({
      cargos: state => state.basket.delivery_Time,
      slides: state => state.basket.delivery_Time.deliveries,
      error_time: state => state.basket.error_time
    })
  },
  data:() => ({
    radioGroup: '',
  }),
  watch: {
    radioGroup: {
      handler: function () {
        const date = this.radioGroup.split(',')
        const Time = {
          date: date[0],
          shift: date[1],
          cargoId: this.cargos.cargos_id
        }
        store.dispatch('set_time_basket', Time)
      }
    }
  }
}
</script>