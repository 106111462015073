<template>
  <v-card class="pa-2 card flat-card" color="surface">
    <v-row class="my-2 mr-2">
      <span class="font onSurface--text" v-text="cargos.cargo_config_title"></span>
    </v-row>
    <v-row class="mx-2">
      <v-col class="pr-0">
      <span class="medium-font onSurface--text">جمع کل مرسوله:</span>
      </v-col>
      <v-col class="text-end pl-0">
      <span class="medium-font onSurface--text" v-text="cargo_price"></span>
      </v-col>
    </v-row>
    <v-row class="mx-2">
      <v-col class="pr-0">
      <span class="medium-font onSurface--text">هزینه حمل:</span>
      </v-col>
      <v-col class="text-end pl-0">
      <span v-if="cargos.post_price == 0" class="medium-font onSurface--text" v-text="cargos.post_price"></span>
      <span v-if="cargos.post_price != 0" class="medium-font onSurface--text" v-text="post"></span>
      </v-col>
    </v-row>
    <div class="overflow">
      <div v-for="error in error_time" :key="error.Gen_Prod_ID_PK">
      <v-row class="mr-1">
        <div class="pa-2">
          <span  class="error--text medium-font" v-text="error.Prod_Title"></span>
        </div>
      </v-row>
      <v-row class="mt-1 mx-1 pa-2 d-flex justify-space-between align-center">
        <span class="error--text medium-font">ظرفیت برای امروز تکمیل است</span>
        <v-btn @click="remove(error.Gen_Prod_ID_PK)" class="error" text x-small>حذف</v-btn>
      </v-row>
      </div>
    </div>
  </v-card>
</template>
<style lang="scss" scoped>
.overflow{
  max-height: 15vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
<script>
import { removeTObasket } from '../../models/Basket'
import { changeTypePrice_format } from '@/models/changeType'
export default {
  props: ['error_time', 'cargos'],
  computed: {
    cargo_price () {
      const price = changeTypePrice_format({
        price: this.$props.cargos.gross_price,
        Tax_price: this.$props.cargos.tax
      })
      return price
    },
    post () {
      const price = changeTypePrice_format({
        price: this.$props.cargos.post_price,
        Tax_price: 0
      })
      return price
    }
  },
  methods: {
    remove (productID) {
      removeTObasket({product_id: productID})
      setTimeout(() => {
        location.reload()
      }, 800);
    }
  }
}
</script>