import router from "@/router"
import axios from "axios"
import Store from '@/store/index'
let config = localStorage.getItem('config')
let _config = {} as any
if (config) {
  _config = JSON.parse(config)
}
export function login(infoLogin: any) {
    Store.dispatch('submit', true)
    Store.dispatch('alertError', {show: false})
    axios.post('/oauth2/token',
    { grant_type: 'password', username: infoLogin.username, password: infoLogin.password, client_id: _config.client_id, client_secret: _config.client_secret }).
    then(Response => {
    if (Response.data.Result.access_token) {
      localStorage.setItem('access_token',Response.data.Result.access_token)
      if (infoLogin.remmber) {
        localStorage.setItem('refresh_token',Response.data.Result.refresh_token)
      }
      Store.dispatch('submit', false)
      router.push('/')
      setTimeout(() => {
        location.reload()
      }, 1000)
    } else {
      Store.dispatch('submit', false)
      if (Response.data.status == 5) {
        Store.dispatch('alertError', {show: true, type: 'not_confirm'})
        setTimeout(() => {
          Store.dispatch('alertError', {show: false, type: 'not_confirm'})
          localStorage.setItem('confirm_login', infoLogin.username)
          router.push('/confirmation')
        }, 2000);
      } else if (Response.data.Result.error == 'invalid_grant') {
        Store.dispatch('alertError', {show: true, type: 'error_pass_user'})
        setTimeout(() => {
          Store.dispatch('alertError', {show: false, type: 'error_pass_user'})
        }, 2000);
      }
    }
    }).catch((error) => {
      // Store.dispatch('submit', false)
      // Store.dispatch('alertError', {show: true, type: 'network'})
    })
}

export function logOut() {
  localStorage.setItem('access_token', '')
  localStorage.setItem('refresh_token', '')
  router.push('/login')
  setTimeout(() => {
    location.reload()
  }, 1000);
}

export function forget_password(mobile: any) {
  Store.dispatch('alertSuccess', {show: false, type: 'send_code'})
  Store.dispatch('submit', true)
  Store.dispatch('alertError', {show: false})
  axios.post('/api/customers/forget', {
    mobile: mobile
  })
  .then(Response => {
    if (Response.data.Result.result == 1) {
      Store.dispatch('submit', false)
      Store.dispatch('alertSuccess', {show: true, type: 'send_code'})
      setTimeout(() => {
        Store.dispatch('alertSuccess', {show: false, type: 'send_code'})
        localStorage.setItem("confirm_login", mobile)
        router.push('/login')
      }, 3000);
    }
  }).catch((error) => {
    // Store.dispatch('submit', false)
    // Store.dispatch('alertError', {show: true, type: 'network'})
  })
}

export function register (info_user: any) {
  Store.dispatch('submit', true)
  Store.dispatch('alertError', {show: false})
  axios.post('/api/Social/Register/register',info_user)
  .then(Response => {
    console.log(Response.data)
  if (!Response.data.Result.error) {
    Store.dispatch('submit', false)
    if (Response.data.Result.Result == 6) {
      Store.dispatch('alertError', {show: true, type: 'registerd'})
      setTimeout(() => {
        Store.dispatch('alertError', {show: false, type: 'registerd'})
      }, 2500)
      if (Response.data.Result.status == 0) {
        setTimeout(() => {
          localStorage.setItem('confirm_login', info_user.mobile)
          router.push('/confirmation')
        }, 2000)
      } else {
        setTimeout(() => {
          router.push('/login')
        }, 2000)
      }
    } else if (Response.data.Result.Result == 1) {
      Store.dispatch('alertSuccess', {show: true, type: 'registerd'})
      setTimeout(() => {
        Store.dispatch('alertSuccess', {show: false, type: 'registerd'})
        localStorage.setItem('confirm_login', info_user.mobile)
        localStorage.setItem('registerd', 'true')
        router.push('/confirmation')
      }, 2000);
    }
  } else {
    Store.dispatch('submit', false)
    Store.dispatch('alertError', {show: true, type: 'network'})
    setTimeout(() => {
      Store.dispatch('alertError', {show: false, type: 'network'})
    }, 2500)
  }
  }).catch((error) => {
    // Store.dispatch('submit', false)
    // Store.dispatch('alertError', {show: true, type: 'network'})
  })
}

export function confirm(confirm_code: string) {
  debugger
  const login_code = localStorage.getItem('confirm_login')
  axios.post('/api/Social/Customers/confirm',{
    "confirm_string" : confirm_code,
    "login_name" : login_code
  }).then(Response => {
    if (Response.data.Result.Result == 1) {
      Store.dispatch('alertSuccess', {show: true, type: 'confirm'})
      setTimeout(() => {
        Store.dispatch('alertSuccess', {show: false, type: 'confirm'})
      }, 2500)
      setTimeout(() => {
        router.push('/login')
        Store.dispatch('alertSuccess', {show: false, type: 'confirm'})
      }, 2000);
    } else if (Response.data.Result.Result == -1) {
      Store.dispatch('alertError', {show: true, type: 'old_confirm'})
      setTimeout(() => {
        Store.dispatch('alertError', {show: false, type: 'old_confirm'})
      }, 2500);
    }
  })
}

export function resend_code() {
  const mobile = localStorage.getItem('confirm_login')
  axios.post('/api/Customers/resendActivationCode', {
    cellphone: mobile
  }).then(Response => {
    if (Response.data.Result.Result == 1) {
      Store.dispatch('alertSuccess', {show: true, type: 'send_again'})
      setTimeout(() => {
        Store.dispatch('alertSuccess', {show: false, type: 'send_again'})
      }, 2500)
    }
  })
}